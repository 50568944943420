import React, { createContext, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Routes/Layout';
import routes from "./Routes";
import Login from './Components/Login/Login';
import { useState } from 'react';

export const CartContext = createContext();
export const CheckoutContext = createContext();

function Appadmin() {


    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('login') === 'true');

    useEffect(() => {
        if (window.location.pathname === "/admin-panel" || window.location.pathname === "/admin-panel/login") {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("login");
            setIsLoggedIn(false);
        }
    }, [window.location.pathname]);

    setInterval(() => {
        if (localStorage.getItem('login')) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, 100)

    const router = createBrowserRouter([
        {
            element: isLoggedIn ? <CartContext.Provider value={''}><Layout /></CartContext.Provider> : <Login />,
            errorElement: "",
            children: routes
        }
    ]);

    return <RouterProvider router={router} />;
}

export default Appadmin;