import React, { createContext, useState } from 'react';
import './App.css';
import Appwebsite from './Website/Appwebsite';
import Appadmin from './Admin/Appadmin';
import { BrowserRouter } from 'react-router-dom';

export const CartContext = createContext();
export const CheckoutContext = createContext();

function App() {

  const [path] = useState(window.location.pathname.startsWith('/admin-panel'));

  if (path) {
    return <Appadmin />
  } else {
    return <Appwebsite />;
  }
}

export default App;
