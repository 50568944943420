import React, { useEffect } from 'react';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";


export default function Header() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const getpath = window.location.pathname

  return (
    <>
      <div id='Header' className={` ${getpath === "/ps-pro" ? "psheader" : ""} header ${isScrolled ? 'sticky' : ''}`}>
        <nav className="navigation">
          <div className='logo'>
            <a href="/" onClick={() => navigate('/')}>
              <div className='img'></div>
            </a>
          </div>
          <button className="hamburger" onClick={() => { setIsNavExpanded(!isNavExpanded); }} >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white" >
              <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd" />
            </svg>
          </button>
          <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"} >
            {isNavExpanded ? (
              <ul>
                <li onClick={() => { setIsNavExpanded(false); navigate('/') }}>
                  <button className="color1" >Home</button>
                </li>
                <li onClick={() => { setIsNavExpanded(false); navigate('/about'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                  <button className="color1" >About Us</button>
                </li>

                <li>
                  <div className="dropdown">
                    <span>Services</span>
                    <i className="fa-solid fa-caret-down"></i>
                    <div className="dropdown-content">
                      <button className='dropdown-p' onClick={() => { navigate('/accounting'); setIsNavExpanded(false); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Accounting</button>
                      <button className='dropdown-p' onClick={() => { navigate('/manufacturing'); setIsNavExpanded(false); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Manufacturing</button>
                      <button className='dropdown-p' onClick={() => { navigate('/ps-pro'); setIsNavExpanded(false); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>PS-PRO</button>
                    </div>
                  </div>
                </li>
                <li onClick={() => { setIsNavExpanded(false); navigate('/contactUs') }}>
                  <button className="color1" >Contact Us</button>
                </li>
                {/* <li onClick={() => { setIsNavExpanded(false); navigate('/') }}>
                  <Link className="color1" to="https://work.goyanitechnologies.com" target="_blank" rel="noreferrer" >Employee Login</Link>
                </li> */}

                {/* <li onClick={() => { setIsNavExpanded(false); navigate('/') }}>
                  <a className="color1" href="/#career" >Career</a>
                </li> */}
              </ul>
            ) : (
              <ul id="ul">
                <li onClick={() => navigate('/')}>
                  <button className="color1" href="/" >Home</button>
                </li>
                <li onClick={() => { navigate('/about'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                  <button className="color1" href="#" >About Us</button>
                </li>

                <li>
                  <div className="dropdown">
                    <span>Services</span>
                    <i className="fa-solid fa-caret-down"></i>
                    <div className="dropdown-content">
                      <button className='dropdown-p' href="#" onClick={() => { navigate('/accounting'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Accounting</button>
                      <button className='dropdown-p' href="#" onClick={() => { navigate('/manufacturing'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Manufacturing</button>
                      <button className='dropdown-p' href=" " onClick={() => { navigate('/ps-pro'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>PS-PRO</button>
                    </div>
                  </div>
                </li>
                <li onClick={() => navigate('/contactus')}>
                  <button className="color1" href="/" >Contact Us</button>
                </li>
                {/* <li >
                  <Link className="color1" to="https://work.goyanitechnologies.com" onClick={() => navigate('/')} target="_blank" rel="noreferrer" >Employee Login</Link>
                </li> */}
                {/* <li onClick={() => navigate('/')}>
                  <button className="color1" href="/career" >Career</button>
                </li> */}
              </ul>
            )}
          </div>
        </nav>
      </div>

      <div className={isNavExpanded ? "BackGround" : ""} onClick={() => { setIsNavExpanded(!isNavExpanded); }} ></div>
    </>
  );
}

