import React, { useEffect } from 'react'
import page404 from "../../../Assets/img/404.svg"
import "./page404.scss"
import { Button } from "primereact/button";
import { useNavigate } from 'react-router-dom';

function Page404() {

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("login");
    })

    let GoToBack = () => {
        navigate('/');
    }

    return (
        <div>
            <div className='page404'>
                <div className='page404-img'>
                    <img src={page404}></img>
                </div>
                <div className='page404-text'>
                    <p>This is wrong. This page does no longer exist, or it never had.</p>
                    <Button className='page404-btn' onClick={() => GoToBack()}>GO BACK</Button>
                </div>
            </div>
        </div>
    )
}

export default Page404;