import React from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../Assets/Img/logo.png';
import linkedin from '../../Assets/Img/in.png';
import facebook from '../../Assets/Img/facebook.png';
import insta from '../../Assets/Img/insta.png';
import youtube from '../../Assets/Img/youtube.png';
import "./Footer.scss";
import map from '../../Assets/Img/map.png';
import phone from '../../Assets/Img/phone.png';
import mail from '../../Assets/Img/mail.png';



function Footer() {
    const navigate = useNavigate();

    const openGoogleMaps = () => {
        window.open('https://goo.gl/maps/55dfDxr7DnKvFxjB7', '_blank');
    };

    const openGoogleMaps2 = () => {
        window.open('https://maps.app.goo.gl/gavUS9aFY44TfVa7A', '_blank');
    };
    const openMoblieNumber = () => {
        window.open('tel:+917878971551', '_blank');
    };
    const openMoblieNumber2 = () => {
        window.open('tel:+919819515853', '_blank');
    };
    const openMoblieNumber3 = () => {
        window.open('tel:+917710081551', '_blank');
    };
    const openMail = () => {
        window.open('mailto:hardik@goyanitechnologies.com?subject = Help&body = I Can Help You !', '_blank');
    };
    const openMail2 = () => {
        window.open('mailto:umang@goyanitechnologies.com?subject = Help&body = I Can Help You !', '_blank');
    };
    const openMail3 = () => {
        window.open('mailto:info@goyanitechnologies.com?subject = Help&body = I Can Help You !', '_blank');
    };


    return (
        <div>
            <section id="footer">
                <div className="contact section-padding pb-1 ">
                    <footer className=" p-2 ">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 p-4 mt-2">

                                    <img src={logo} href="#/" style={{ width: "245px" }} alt='img' />
                                    <div className="row">
                                        <div className='col-lg-6'>
                                            <div className='f_office'>Registered Office</div>
                                            <div className='f_map2'>
                                                <img onClick={openGoogleMaps2} src={map} alt='img' className='f_mapimg'></img>
                                                <p className='f_adress2' onClick={openGoogleMaps2}>6th Floor, 61/A, Shridhar Smruti A CHS LTD, Eksar
                                                    Road, Borivali West, Mumbai Suburban, Maharashtra (400092) </p>
                                            </div>
                                            <div className='f_office'>Branch</div>

                                            <div className='f_map'>
                                                <img onClick={openGoogleMaps} src={map} alt='img' className='f_mapimg'></img>
                                                <p className='f_adress' onClick={openGoogleMaps}>A-703/704, Diamond World, Mangadh chowk, Mini bazar, Surat (395006)</p>
                                            </div>
                                        </div>

                                        <div className='col-lg-5'>
                                            <div className="f_contactus">Contact Us </div>
                                            <div className='f_gmail'>
                                                <img src={mail} alt='img' className='mapimg'></img>
                                                <div className='f_gmail_text'>
                                                    <p className='f_gmail1' onClick={openMail}>hardik@goyanitechnologies.com</p>
                                                    <p className='f_gmail2' onClick={openMail2}>umang@goyanitechnologies.com</p>
                                                    <p className='f_gmail2' onClick={openMail3}>info@goyanitechnologies.com</p>
                                                </div>
                                            </div>
                                            <div className='phone'>
                                                <img src={phone} alt='img' className='mapimg'></img>
                                                <div className='phone_text'>
                                                    <p className='phone1' onClick={openMoblieNumber}>+91 78789 71551</p>
                                                    <p className='phone2' onClick={openMoblieNumber2}>+91 98195 15853</p>
                                                    <p className='phone2' onClick={openMoblieNumber3}>+91 77100 81551</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-3 p-4">
                                    <h3 className="footertext f_fontfamily"> Info
                                    </h3>
                                    <p className="mb-2 footertext privacy mt-3" onClick={() => { navigate('/privacy'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Privacy Policy
                                    </p>
                                    <p className="mb-2 footertext privacy" onClick={() => { navigate('/termandcodition'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Terms and conditions Policy
                                    </p>
                                    <p className="mb-2 footertext privacy" onClick={() => { navigate('/refundpolicy'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Refund & Cancellation Policy
                                    </p>

                                    <h3 className="footertext f_fontfamily mt-4"> iOS/Android
                                    </h3>
                                    <p className="mb-2 footertext privacy mt-3" onClick={() => { navigate('/privacyiosandroid'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>Privacy Policy
                                    </p>

                                </div>
                                <div className="col-lg-3 col-md-3 p-4">
                                    <h3 className="footertext f_fontfamily "> Services
                                    </h3>
                                    <p className="mb-2 footertext privacy mt-3"><a href="#services" onClick={() => navigate('/')}>Manufacturing</a>
                                    </p>
                                    <p className="mb-2 footertext privacy"><a href="#services" onClick={() => navigate('/')}>ERP</a>
                                    </p>
                                    <p className="mb-2 footertext privacy"><a href="#services" onClick={() => navigate('/')}>Diamond Accounting</a>
                                    </p>
                                    <p className="mb-2 footertext privacy"><a href="#services" onClick={() => navigate('/')}>Trading</a>
                                    </p>
                                    <p className="mb-2 footertext privacy"><a href="#services" onClick={() => navigate('/')}>Payroll</a>
                                    </p>
                                    <p className="mb-2 footertext privacy"><a href="#services" onClick={() => navigate('/')}>Packet Sorting Robot</a>
                                    </p>

                                </div>
                            </div>

                        </div>

                        <hr className="text-white" />

                        <div className="container ">
                            <div className="row">
                                <div className="col-md-6 text-md-start text-center lineheight">
                                    <span className="text-white footercopyright">Copyright © 2023 All Rights Reserved</span>
                                </div>

                                <div className="col-md-6 text-md-end text-center ">
                                    <div className="facebook">
                                        <a href="https://in.linkedin.com/in/hardik-goyani-49678019" target="/_blank"><img src={linkedin} alt='img' className='linkede linkd ' /></a>

                                        <a href="https://www.facebook.com/GoyaniTechnologies/" target="/_blank"><img src={facebook} alt='img' className='linkede'></img></a>
                                        <a href="https://instagram.com/goyani_technologies?igshid=MzRlODBiNWFlZA=="
                                            className=" " target="/_blank"><img src={insta} alt='img' className='linkede'></img></a>
                                        <a href="https://www.youtube.com/@goyanitechnologies795" className=" "
                                            target="/_blank"><img src={youtube} alt='img' className='linkede'></img></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </section>
        </div>
    )
}

export default Footer