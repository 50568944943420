import React, { useState } from 'react';
import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { FloatLabel } from "primereact/floatlabel";
import "./Login.scss";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  const navigate = useNavigate();


  useEffect(() => {
    if (window.location.pathname === "/admin-panel" || window.location.pathname === "/admin-panel/" || window.location.pathname === "/admin-panel/login" ) {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("login");
      navigate('/admin-panel');
    }

  }, [window.location.pathname]);



  const handleSubmit = () => {

    if (username === 'Admin' && password === '123') {
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      localStorage.setItem("login", true);
      navigate("/admin-panel/blogs");
    } else {
      // Handle invalid credentials
      alert('Invalid username or password');
    }
  }


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  return (
    <div id='Login'>
      <div className='Login-box'>
        <div className='header-text'>
          <h1 className="">Login</h1>

        </div>
        <div className='Login-form'>
          <div className='input'>
            <FloatLabel>
              <InputText id="username" value={username} onChange={handleUsernameChange} />
              <label htmlFor="username">Username</label>
            </FloatLabel>
          </div>
          <div className="input">
            <FloatLabel>
              <Password feedback={false} toggleMask inputId="password" value={password} onChange={handlePasswordChange} />
              <label htmlFor="password">Password</label>
            </FloatLabel>
          </div>
          <button onClick={() => handleSubmit()}>Login</button>
        </div>
      </div>
    </div>
  )
}

export default Login;