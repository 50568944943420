const PathConstants = {
    website: "/",
    home: "home",
    about: "about",
    accounting: "accounting",
    manufacturing: "manufacturing",
    pspro: "ps-pro",
    contactus: "contactus",
    privacy: "privacy",
    termandcodition: "termandcodition",
    refundpolicy: "refundpolicy",
    privacyiosandroid: "privacyiosandroid",
}

export default PathConstants;