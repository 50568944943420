import React from 'react'
import { useNavigate } from 'react-router-dom';
import "./Header.scss"

function Header() {
    const navigate = useNavigate();

    return (
        <div id='Header-blogs' className='header-blogs'>
            <nav className="navigation">
                <div className='logo'>
                    <a href="/" onClick={() => navigate('/')}>
                        <div className='img'></div>
                    </a>
                </div>

                <div className="navigation-menu" >
                    <ul id="ul">
                        <li onClick={() => { navigate('/admin-panel/blogs'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                            <button className="color1" >Home</button>
                        </li>
                        <li onClick={() => { navigate('/admin-panel/blogsdetails'); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) }}>
                            <button className="color1" >Blogs Add</button>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Header