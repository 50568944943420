import React, { createContext } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './Routes/Layout';
import routes from "./Routes";
import Page404 from './Components/404pages/Page404';
import "./Appwebsite.scss"

export const CartContext = createContext();
export const CheckoutContext = createContext();

function Appwebsite() {

    const router = createBrowserRouter([
        {
            element: <CartContext.Provider value={''}><Layout /></CartContext.Provider>,
            errorElement: window.location.pathname === '500' ? <Page404 /> : <Page404 />,
            children: routes
        }
    ]);

    return <RouterProvider router={router} />;
}

export default Appwebsite;