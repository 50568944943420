import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import GoogleTranslate from "../../GoogleTranslate";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import demofree from "./../Assets/Img/FORDEMO.png"

function Layout() {

    return (
        <React.Fragment>
            <Header />
            <Suspense><Outlet /></Suspense>
            <Footer />
            <div className="fordemo">
                <a target='_blank' href='tel:+919819515853' rel="noreferrer">
                    <img src={demofree} alt="img" className="demoimg" ></img>
                </a>
            </div> 
        </React.Fragment>
    );
}
export default Layout;