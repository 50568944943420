import React from 'react';
import PathConstants from './pathConstants';

const Home = React.lazy(() => import("../Pages/Home/Home"));
const About = React.lazy(() => import("../../Website/Components/Aboutus/About"));
const Accounting = React.lazy(() => import("../../Website/Components/Product/Accounting"));
const Manufacturing = React.lazy(() => import("../../Website/Components/Product/Manufacturing"));
const Pspro = React.lazy(() => import("../../Website/Components/Machine/Machine"));
const Contactus = React.lazy(() => import("../../Website/Components/Contactus/Contactus"));
const Privacy = React.lazy(() => import("../../Website/Components/Footerdetails/Privacy"));
const Termandcodition = React.lazy(() => import("../../Website/Components/Footerdetails/Termandcodition"));
const Refundpolicy = React.lazy(() => import("../../Website/Components/Footerdetails/Refundpolicy"));
const Privacyiosandroid = React.lazy(() => import("../../Website/Components/Footerdetails/PrivacyiOSAndroid"));

const routes = [
    { path: PathConstants.website, element: <Home /> },
    { path: PathConstants.home, element: <Home /> },
    { path: PathConstants.about, element: <About /> },
    { path: PathConstants.accounting, element: <Accounting /> },
    { path: PathConstants.manufacturing, element: <Manufacturing /> },
    { path: PathConstants.pspro, element: <Pspro /> },
    { path: PathConstants.contactus, element: <Contactus /> },
    { path: PathConstants.privacy, element: <Privacy /> },
    { path: PathConstants.termandcodition, element: <Termandcodition /> },
    { path: PathConstants.refundpolicy, element: <Refundpolicy /> },
    { path: PathConstants.privacyiosandroid, element: <Privacyiosandroid /> },
]

export default routes;