import React from 'react';
import PathConstants from './pathConstants';

const Login = React.lazy(() => import("../Components/Login/Login"));
const Blogs = React.lazy(() => import("../Components/Pages/Blogs/Blogs"));
const BlogsDetails = React.lazy(() => import("../Components/Pages/BlogsDetails/BlogsDetails"));

const routes = [
    { path: PathConstants.admin, element: <Login /> },
    { path: PathConstants.login, element: <Login /> },
    { path: PathConstants.blog, element: <Blogs /> },
    { path: PathConstants.blogsdetails, element: <BlogsDetails /> },
    { path: PathConstants.blogsdetailsID, element: <BlogsDetails /> },
]

export default routes;