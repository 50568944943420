import React, { Suspense } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header/Header";

function Layout() {


    useEffect(() => {
        if (window.location.pathname === "/admin-panel" || window.location.pathname === "/admin-panel/" || window.location.pathname === "/admin-panel/login") {
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("login");

        }
    }, [window.location.pathname]);
    return (
        <React.Fragment>
            <Header />
            <Suspense><Outlet /></Suspense>
        </React.Fragment>
    );
}
export default Layout;